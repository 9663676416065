<template>
    <div>
        <Header :customstyle="{ background: '#0C1913' }">
            <template slot="title">实名认证</template>
        </Header>
        <div class="content">
            <div class="card">
                <div class="info">
                    <div class="title">头像面</div>
                    <div class="subtitle">上传您身份证头像面</div>
                </div>
                <div>
                    <van-uploader  @oversize="onOversize" :max-size="5000 * 1024" :after-read="clickHeaderUpload">
                        <img class="idcard" :src = "heardIdcard" />
                    </van-uploader>
                    
                </div>
            </div>
            <div class="card offset">
                <div class="info">
                    <div class="title">国徽面</div>
                    <div class="subtitle">上传您身份认证国徽面</div>
                </div>
                <div>
                    <van-uploader  @oversize="onOversize" :max-size="5000 * 1024" :after-read="clickUpload">
                        <img class="idcard" :src = "guoIdcard" />
                    </van-uploader>
                </div>
            </div>
          <!--  <div class="card offset">
                <div class="info">
                    <div class="title">手持身份证</div>
                    <div class="subtitle">上传您的手持身份证照片</div>
                </div>
                <div>
                    <van-uploader  @oversize="onOversize" :max-size="5000 * 1024" :after-read="clicksUpload2">
                        <img class="idcard" :src ="photo_id3" />
                    </van-uploader>
                </div>
            </div>-->
            <div class="offset">
                <van-form @submit="onSubmit">
                    <van-field class="inputClass" v-model="username" name="姓名" label="姓名" placeholder="请输入姓名" :rules="[{ required: true, message: '请输入姓名' }]"
                ></van-field>
                    <van-field  class="inputClass" v-model="idcard" name="身份证" label="身份证" placeholder="请输入身份证号" :rules="[{ required: true, message: '请输入身份证号' }]"
                    ></van-field>
                    <van-field  class="inputClass" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码" type="password" :rules="[{ required: true, message: '请输入安全密码' }]"
                    ></van-field>
                    <div class="notice">
                        温馨提示;<br>
                        1、拍照时请确保证件拍摄完整，不出现缺角，<br>
                        2、建议将手机横着拍摄证件;<br>
                        3、光线亮度均匀，无反光; <br>
                        4、照片内容清晰可见，容易识别;<br>
                        否则将不能通过识别系统。<br>
                    </div>
                    <div class="submitbtn">
                        <van-button class="btn" native-type="submit">提交</van-button>
                    </div>
                    
                </van-form>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/header.vue";
import { Toast } from 'vant';
import {uploadImg, authentication,getUserInfo} from '@/request/api'
export default {
    components: {
        Header
    },
    data () {
        return {
            username:'',
            idcard:'',
            password: '',
            heardIdcard: require('@/assets/images/mine/idcard_z.png'),
            guoIdcard: require('@/assets/images/mine/idcard_z.png'),
            photo_id3: require('@/assets/images/card.png'),
            tmpimg: 0,
            address:'',
            userInfo:{},
        }
    },
    activated () {
        this.address = sessionStorage.getItem("address");
        if(this.address){
            this.getUserInfo();
        }
        this.username=''
        this.idcard = ''
        this.password = ''
        this.heardIdcard= require('@/assets/images/mine/idcard_z.png')
        this.guoIdcard=require('@/assets/images/mine/idcard_z.png')
        // this.photo_id3= require('@/assets/images/card.png')
        this.tmpimg = 0
    },
    methods: {
        getUserInfo(){
            getUserInfo({address:this.address}).then((res)=> {
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("level_id");
                sessionStorage.setItem("user", JSON.stringify(res.data));
                sessionStorage.setItem("level_id", res.data.level_id);
                console.log(sessionStorage.getItem("user"))
                this.userInfo = res.data;
            })
        },
        onSubmit() {
            let _this = this;
            if(!this.address){
                Toast('请使用币安智能链链接')
                return
            }
            if(this.address&&this.userInfo.has_paypwd!=1){
                Toast('请先完善资料,设置安全密码');
                setTimeout(function () {
                    _this.$router.push({
                        path:'/home/mine'
                    })
                })
                return
            }
            if(this.heardIdcard === require('@/assets/images/mine/idcard_z.png')) {
                Toast('请上传身份证正面')
                return
            }
            if(this.guoIdcard === require('@/assets/images/mine/idcard_z.png')) {
                Toast('请上传身份证国徽面')
                return
            }
            // if(this.photo_id3 === require('@/assets/images/card.png')) {
            //     Toast('请上传手持身份证照片')
            //     return
            // }
            const params = {
                address: this.address,
                u_name:this.username,
                photo_id: this.idcard,
                photo_id1:this.heardIdcard,
                photo_id2:this.guoIdcard,
                // photo_id3:this.photo_id3,
                pay_code:this.password
            }
            authentication(params).then(res=> {
                if(res.code==200) {
                    Toast('申请成功')
                }
                else if(res.code==100001){
                    Toast('安全密码错误')
                }
                else if(res.code==100002){
                    Toast('该身份信息已存在')
                }
                else if(res.code==10003){
                    Toast('实名认证姓名必须与支付方式姓名一致')
                }else{
                    Toast('申请失败')
                }
            })
        },
        clicksUpload2(file) {
            this.tmpimg = 2
            this.uploadImg(file)
        },
        clickUpload (file) {
            this.tmpimg = 1
            this.uploadImg(file)
        },
        clickHeaderUpload(file) {
            this.tmpimg = 0
            this.uploadImg(file)
        },
        uploadImg(file) {
            const fd = new FormData()
            fd.append('file', file.file)
            uploadImg(fd).then(res=>{
                if(this.tmpimg === 0){
                    this.heardIdcard = res.data.http_url
                }else if (this.tmpimg === 1){
                    this.guoIdcard = res.data.http_url
                }else if (this.tmpimg === 2){
                    this.photo_id3 = res.data.http_url
                }
            })
        },
        onOversize () {
            Toast('文件大小不能超过5M');
        }
    }
}
</script>

<style lang="scss" scoped>
.offset {
    margin-top: 24px;
}
.content {
    background: #0C1913;
    min-height: 100vh;
    padding: 24px 32px 0px 32px;
    .card {
        width: 686px;
        height: 284px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(32,40,35,1);
        display: flex;
        justify-content: space-around;
        align-items: center;
        .info {
            .title {
                color: rgba(214,255,227,1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
            }
            .subtitle {
                color: rgba(139,166,149,1);
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
                line-height: 36px;
            }
        }
        .idcard {
            width: 360px;
            height: 236px;
            border-radius: 10px;
        }
    }
    .inputClass {
        background: #29332D;
        ::v-deep .van-field__control{
            color: white;
        }
        .sendbtn {
            color: rgba(39,204,127,1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
        }
    } 
    .notice {
        margin-top:24px;
        opacity: 1;
        color: rgba(182,217,195,1);
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        line-height: 44px;
    }
    .submitbtn {
        margin-top: 36px;
        display: flex;
        justify-content: center;
        .btn {
            width: 320px;
            height: 88px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(39,204,127,1);
            border-width: 0px;
            color: rgba(32,40,35,1);
            font-size: 36px;
            font-weight: 700;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 88px;
        }
    }
}
</style>